// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `pinko-preview, .pinko-preview {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  position: relative;
  box-sizing: border-box;
  background-color: #FFFFFF;
  overflow: hidden;
}
pinko-preview > header, .pinko-preview > header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 24px 24px 16px 24px;
}
pinko-preview > header div.title div.label, .pinko-preview > header div.title div.label {
  font-family: "Incinet Semi Bold", sans-serif;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  color: #1F1F1F;
}
pinko-preview > header div.title div.description, .pinko-preview > header div.title div.description {
  font-family: "Incinet Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  color: rgba(22, 22, 22, 0.5019607843);
  margin-top: 4px;
}
pinko-preview > header div.toolbar, .pinko-preview > header div.toolbar {
  display: flex;
  flex-direction: row;
}
pinko-preview > nav, .pinko-preview > nav {
  padding: 0 24px 16px 24px;
}
pinko-preview > article, .pinko-preview > article {
  flex-grow: 1;
  overflow: auto;
  padding: 0 24px;
}
pinko-preview > article div.title, .pinko-preview > article div.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
pinko-preview > article div.title div.label, .pinko-preview > article div.title div.label {
  font-family: "Incinet Semi Bold", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  color: #1F1F1F;
}
pinko-preview > article section, .pinko-preview > article section {
  position: relative;
  background-color: rgba(22, 22, 22, 0.0392156863);
  padding: 8px 12px;
  margin-bottom: 16px;
  border-radius: 8px;
}
pinko-preview > article section div.row, .pinko-preview > article section div.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  border-bottom: 1px solid rgba(111, 111, 111, 0.1019607843);
}
pinko-preview > article section div.row:last-child, .pinko-preview > article section div.row:last-child {
  border-bottom: none;
}
pinko-preview > article section div.row > div.label, .pinko-preview > article section div.row > div.label {
  font-family: "Incinet Medium", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  color: rgba(31, 31, 31, 0.6980392157);
  padding: 8px 0;
}
pinko-preview > article section div.row > div.value, .pinko-preview > article section div.row > div.value {
  font-family: "Incinet Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  color: #1F1F1F;
  padding: 8px 0;
}
pinko-preview > article section div.row > div.value-right, .pinko-preview > article section div.row > div.value-right {
  text-align: right;
}
pinko-preview > article section div.row > div.value-flex, .pinko-preview > article section div.row > div.value-flex {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
pinko-preview > article section div.row-top, .pinko-preview > article section div.row-top {
  align-items: flex-start;
}
pinko-preview > article div.activities, .pinko-preview > article div.activities {
  margin-bottom: 32px;
}
pinko-preview > article div.activities div.period, .pinko-preview > article div.activities div.period {
  font-family: "Incinet Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  color: rgba(31, 31, 31, 0.6980392157);
  padding: 12px 0 8px 0;
  text-align: center;
}
pinko-preview > article div.activities div.activity, .pinko-preview > article div.activities div.activity {
  max-width: 64%;
  margin-bottom: 4px;
}
pinko-preview > article div.activities div.activity div.user, .pinko-preview > article div.activities div.activity div.user {
  font-family: "Incinet Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  color: rgba(31, 31, 31, 0.6980392157);
  padding: 8px 0 4px 0;
}
pinko-preview > article div.activities div.activity p.text, .pinko-preview > article div.activities div.activity p.text {
  font-family: "Incinet Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  padding: 8px 12px;
  border-radius: 8px;
}
pinko-preview > article div.activities div.activity p.text span.timestamp, .pinko-preview > article div.activities div.activity p.text span.timestamp {
  font-family: "Incinet Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  display: block;
  color: rgba(31, 31, 31, 0.6980392157);
  text-align: right;
  margin-top: 4px;
}
pinko-preview > article div.activities div.activity-user, .pinko-preview > article div.activities div.activity-user {
  margin-left: auto;
}
pinko-preview > article div.activities div.activity-user div.user, .pinko-preview > article div.activities div.activity-user div.user {
  text-align: right;
}
pinko-preview > article div.activities div.activity-user p.text, .pinko-preview > article div.activities div.activity-user p.text {
  color: #1F1F1F;
  background-color: rgba(22, 22, 22, 0.0392156863);
}
pinko-preview > article div.activities div.activity-system, .pinko-preview > article div.activities div.activity-system {
  margin-right: auto;
}
pinko-preview > article div.activities div.activity-system div.user, .pinko-preview > article div.activities div.activity-system div.user {
  text-align: left;
}
pinko-preview > article div.activities div.activity-system p.text, .pinko-preview > article div.activities div.activity-system p.text {
  color: #1F1F1F;
  background-color: rgba(128, 0, 247, 0.3019607843);
}
pinko-preview > article div.submit, .pinko-preview > article div.submit {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
pinko-preview > footer, .pinko-preview > footer {
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding: 24px;
}`, "",{"version":3,"sources":["webpack://./src/pinko/components/pinko-preview/pinko-preview.scss","webpack://./src/pinko/styles/_mixins.scss","webpack://./src/pinko/styles/_colors.scss","webpack://./src/pinko/styles/_styles.scss"],"names":[],"mappings":"AAIA;EC6CE,YAAA;EATA,aAAA;EACA,sBAAA;EAuDA,mBAAA;EDxFA,kBAAA;EACA,sBAAA;EACA,yBEsDY;EFrDZ,gBAAA;AAFF;AAIE;ECsBA,aAAA;EACA,mBAAA;EDrBE,8BAAA;EACA,uBAAA;EACA,4BAAA;AADJ;AAIM;ECXJ,4CAAA;EE2LA,eAAA;EACA,kBAAA;EACA,mBAAA;EHhLM,cEJa;AFKrB;AAEM;ECxBJ,0CAAA;EEkBA,eAAA;EACA,kBAAA;EACA,mBAAA;EHMM,qCEPc;EFQd,eAAA;AAGR;AACI;ECGF,aAAA;EACA,mBAAA;ADDF;AAEE;EACE,yBAAA;AAAJ;AAGE;ECQA,YAAA;EDNE,cAAA;EACA,eAAA;AADJ;AAGI;ECXF,aAAA;EACA,mBAAA;EDYI,mBAAA;EACA,8BAAA;EACA,kBAAA;AAAN;AAEM;EC3CJ,4CAAA;EEsKA,eAAA;EACA,kBAAA;EACA,mBAAA;EH3HM,cEpCa;AFuCrB;AACI;EACE,kBAAA;EACA,gDEpDQ;EFqDR,iBAAA;EACA,mBAAA;EACA,kBAAA;AACN;AACM;EC9BJ,aAAA;EACA,mBAAA;ED+BM,mBAAA;EACA,8BAAA;EACA,SAAA;EACA,0DAAA;AAER;AAAQ;EACE,mBAAA;AAEV;AACQ;ECvEN,yCAAA;EE4FA,eAAA;EACA,kBAAA;EACA,mBAAA;EHrBQ,qCE3Da;EF4Db,cAAA;AAIV;AADQ;ECjFN,0CAAA;EEkBA,eAAA;EACA,kBAAA;EACA,mBAAA;EH+DQ,cElEW;EFmEX,cAAA;AAMV;AAJU;EACE,iBAAA;AAMZ;AAHU;ECxDR,aAAA;EACA,mBAAA;EDyDU,QAAA;AAMZ;AAFQ;EACE,uBAAA;AAIV;AACI;EACE,mBAAA;AACN;AACM;ECzGJ,0CAAA;EEIA,eAAA;EACA,kBAAA;EACA,mBAAA;EHqGM,qCEzFe;EF0Ff,qBAAA;EACA,kBAAA;AAIR;AADM;EACE,cAAA;EACA,kBAAA;AAGR;AADQ;ECpHN,0CAAA;EEIA,eAAA;EACA,kBAAA;EACA,mBAAA;EHgHQ,qCEpGa;EFqGb,oBAAA;AAMV;AAHQ;EC1HN,0CAAA;EEkBA,eAAA;EACA,kBAAA;EACA,mBAAA;EHwGQ,iBAAA;EACA,kBAAA;AAQV;AANU;EC/HR,0CAAA;EEIA,eAAA;EACA,kBAAA;EACA,mBAAA;EH2HU,cAAA;EACA,qCEhHW;EFiHX,iBAAA;EACA,eAAA;AAWZ;AAPQ;EACE,iBAAA;AASV;AAPU;EACE,iBAAA;AASZ;AANU;EACE,cE/HS;EFgIT,gDE1IE;AFkJd;AAJQ;EACE,kBAAA;AAMV;AAJU;EACE,gBAAA;AAMZ;AAHU;EACE,cE5IS;EF6IT,iDEjJE;AFsJd;AACI;EClIF,aAAA;EACA,mBAAA;EDmII,QAAA;AAEN;AAEE;ECxIA,aAAA;EACA,mBAAA;EDyIE,QAAA;EACA,aAAA;AACJ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
