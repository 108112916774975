// Create component map to register decorator
// Decorators are not executed on import statement
import { PinkoTable } from "../pinko/components/pinko-table/pinko-table.js";
import { PinkoInput } from "../pinko/components/pinko-input/pinko-input.js";
import { PinkoForm } from "../pinko/components/pinko-form/pinko-form.js";
import { PinkoButton } from "../pinko/components/pinko-button/pinko-button.js";
import { IncinetApp } from "./components/incinet-app/incinet-app.js";
import { MainMenu } from "./components/main-menu/main-menu.js";
import { UserBrowser } from "./components/user-browser/user-browser.js";
import { LoginPage } from "./components/login-page/login-page.js";
import { PartnerBrowser } from "./components/partner-browser/partner-browser.js";
import { PinkoFilter } from "../pinko/components/pinko-filter/pinko-filter.js";
import { PinkoIcon } from "../pinko/components/pinko-icon/pinko-icon.js";
import { PinkoTag } from "../pinko/components/pinko-tag/pinko-tag.js";
import { PinkoSearch } from "../pinko/components/pinko-search/pinko-search.js";
import { PinkoAction } from "../pinko/components/pinko-action/pinko-action.js";
import { PinkoSelect } from "../pinko/components/pinko-select/pinko-select.js";
import { PinkoPreview } from "../pinko/components/pinko-preview/pinko-preview.js";
import { PartnerPreview } from "./components/partner-preview/partner-preview.js";
import { PinkoTabs } from "../pinko/components/pinko-tabs/pinko-tabs.js";
import { PinkoSidebar } from "../pinko/components/pinko-sidebar/pinko-sidebar.js";
import { PinkoOverlay } from "../pinko/components/pinko-overlay/pinko-overlay.js";
import { PinkoDialog } from "../pinko/components/pinko-dialog/pinko-dialog.js";
import { PartnerCreateDialog } from "./components/partner-create-dialog/partner-create-dialog.js";
import { PartnerAddressDialog } from "./components/partner-address-dialog/partner-address-dialog.js";

export const DEFINE_MAP: any = {
    PinkoOverlay,
    PinkoDialog,
    PinkoTable,
    PinkoInput,
    PinkoSelect,
    PinkoForm,
    PinkoButton,
    PinkoFilter,
    PinkoIcon,
    PinkoTag,
    PinkoSearch,
    PinkoAction,
    PinkoPreview,
    PinkoTabs,
    PinkoSidebar,
    PartnerPreview,
    IncinetApp,
    MainMenu,
    UserBrowser,
    LoginPage,
    PartnerBrowser,
    PartnerCreateDialog,
    PartnerAddressDialog,
}

// Create app component end attach to DOM
// Erase previously displayed loader
document.body.innerHTML = "<incinet-app></incinet-app>";