import "./partners-browser.scss";
import template from "./partners-browser.hbs";
import { component } from "../../../hiyo/decorators.js";
import { IncinetContext } from "../../context/incinet-context.js";
import { PartnersBrowserOptions } from "./types.js";
import { PinkoFilter } from "../../../pinko/components/pinko-filter/pinko-filter.js";
import { PinkoSelect } from "../../../pinko/components/pinko-select/pinko-select.js";
import { FilterItem } from "../../../pinko/components/pinko-filter/types.js";
import { PinkoTable } from "../../../pinko/components/pinko-table/pinko-table.js";
import { PinkoComponent } from "../../../pinko/components/pinko-component/pinko-component.js";
import { ObjectHelper } from "../../../hiyo/object-helper.js";
import { TableColumn, TableRow } from "../../../pinko/components/pinko-table/types";
import { SystemHelper } from "../../../hiyo/system-helper.js";

@component(template, true)
export class PartnersBrowser extends PinkoComponent<IncinetContext, PartnersBrowserOptions> {

    // Components
    public filter: PinkoFilter;
    public table: PinkoTable;

    public onCreate() {
        // Create all components
        this.createFilter();
        this.createTable();
    }

    public createFilter(): void {
        // Create filter
        this.filter = new PinkoFilter(this.context, {
            title: "Seznam partnerů",
            items: [
                {
                    name: "Reload",
                    icon: "Reload",
                }
            ],
            selects: [
                new PinkoSelect(this.context, {
                    type: "Tag",
                    name: "addresses.country",
                    placeholder: "Country",
                    items: [
                        {
                            name: "USA",
                            label: "USA"
                        },
                        {
                            name: "UK",
                            label: "UK"
                        },
                        {
                            name: "CZ",
                            label: "Česko"
                        }
                    ]
                }),
                new PinkoSelect(this.context, {
                    type: "Tag",
                    name: "producer",
                    placeholder: "Výrobci",
                    items: [
                        {
                            name: "Domestic",
                            label: "Domácí"
                        },
                        {
                            name: "International",
                            label: "Zahraniční"
                        }
                    ]
                })

            ]
        });

        // Reload table
        this.filter.onSearch = async () => {
            await this.load();
        }

        // Reload selected
        this.filter.onItemSelect = (item: FilterItem) => {
            // Render
            //this.filter.render();
        }
    }

    public createTable(): void {
        this.table = new PinkoTable(this.context, {
            type: "SingleSelect",
            size: "Short",
            height: "100%",
            rows: {
                id: "id",
                decorator: (data: any): string => {
                    return data.disabled ? "disabled" : null;
                }
            },
            columns: [
                {
                    name: "name",
                    type: "String",
                    property: "name",
                    label: "common.columns.name",
                    width: 280,
                    sortable: true,
                    selected: true
                },
                {
                    name: "addresses.country",
                    type: "String",
                    property: (data: any) => {
                        return data.addresses.find(x => x.type == "Legal")?.country
                    },
                    label: "common.columns.country",
                    width: 120,
                    ellipsis: true,
                    sortable: true
                },
                {
                    name: "note",
                    type: "String",
                    property: "note",
                    label: "common.columns.note",
                    minWidth: 280,
                    sortable: true
                }
            ]
        });

        // Reload on sort
        this.table.onColumnSelect = async (column: TableColumn) => {
            // Force load
            await this.load();
        }

        // OnRowSelect handle
        this.table.onRowSelect = (row: TableRow) => {
        }

    }

    protected openPreview(data: any): void {
        // Detail options
        /*let options: TrafficIncidentDetailOptions = {
            style: "Light",
            incident: data,
            title: `enums.TrafficIncidentType.${data.type}`,
            subtitle: data.item.name,
            printable: false,
            closable: true
        }

        // Detail already visible
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = options;

            // Redraw completely
            this.detail.invalidate();

            // Not continue
            return;
        }

        // New detail
        this.detail = new TrafficIncidentDetail(this.context, options);

        // Unselect table row and null detail
        this.detail.onClose = () => {
            this.table.unselectRow(this.detail.options.incident.id);
            this.detail = null;
        }

        // Show detail
        this.detail.attach();*/
        console.info(data);
    }

    async load(): Promise<void> {
        // Lock control
        await this.lock();

        // Data from filter
        let data = this.filter.getData();

        // Url with host
        let url = new URL(`${this.context.api.options.host}/partners`);

        // Pagination
        //url.searchParams.set("pageSize", `${this.pagination.options.pageSize || PAGE_SIZE}`);
        //url.searchParams.set("page", `${this.pagination.options.page}`);

        // Add sort by selected column
        let column = this.table.options.columns.find(x => x.selected);
        if (column) {
            // Sort parameter
            let sort = `${column.name}:${column.descendent ? "desc" : "asc"}`;

            // Second sort condition enabled?
            if (column.extraSort) {
                sort += `,${column.extraSort}`;
            }

            // Set final sort parameter
            url.searchParams.set("sort", sort);
        }

        // Query building
        if (data) {
            for (let key of Object.keys(data)) {
                let value = data[key];

                // Skip null value
                if (value == null) {
                    continue;
                }

                // Comma separated values
                else if (typeof value == "object") {
                    url.searchParams.set(key, ObjectHelper.toCommaKeys(value));
                }
                // String value
                else {
                    url.searchParams.set(key, value);
                }
            }
        }

        console.info(url);

        //await SystemHelper.sleep(2000);
        // Get clients
        let resource = await this.context.api.getResource(`${url.pathname}${url.search}`);

        // Set data to table
        this.table.setData(resource.data);

        // Unlock control
        await this.unlock();
    }
}