import "./login-page.scss";
import template from "./login-page.hbs";
import { component, query } from "../../../hiyo/decorators.js";
import { PinkoComponent } from "../../../pinko/components/pinko-component/pinko-component.js";
import { IncinetContext } from "../../context/incinet-context.js";
import { PinkoForm } from "../../../pinko/components/pinko-form/pinko-form.js";
import { MathHelper } from "../../../hiyo/math-helper.js";
import { SystemHelper } from "../../../hiyo/system-helper.js";

@component(template, true)
export class LoginPage extends PinkoComponent<IncinetContext> {

    // Child components
    @query("pinko-form") public form: PinkoForm;

    public onCreate(): void {
        // Random background motive picker
        this.classList.add(`background-${MathHelper.random(1, 9)}`);
    }

    public onRender() {
        // Log in on form submit
        this.form.onSubmit = async (data: any) => {
            await this.login(data);
        }
    }

    public async load(): Promise<void> {
        await SystemHelper.sleep(2000);
    }

    public async login(data: any): Promise<void> {
        // Show loader
        this.lock();

        try {
            // API call
            let user = await this.context.api.loginUser(data.email, data.password);

            // Fire login event
            this.fire("login", user);

            // Fade out
            this.querySelector<HTMLElement>("div.dialog").style.opacity = "0";
        }
        catch (e) {
            this.form.setReason("password", "validation.invalidUsernameOrPassword")
        }

        //await SystemHelper.sleep(5000);

        // Hide loader
        this.unlock();
    }
}