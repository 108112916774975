import "./pinko-sidebar.scss";
import template from "./pinko-sidebar.hbs";
import { component, events } from "../../../hiyo/decorators.js";
import { PinkoComponent } from "../pinko-component/pinko-component.js";
import { Context } from "../../../hiyo/context.js";
import { SidebarGroup, SidebarItem, PinkoSidebarOptions } from "./types.js";
import { Log } from "../../../hiyo/log.js";

@component(template)
@events("click")
export class PinkoSidebar extends PinkoComponent<Context, PinkoSidebarOptions> {

    // Event handling methods
    public onSelect(item: SidebarItem, group?: SidebarGroup): void {};

    public find(itemName: string): SidebarItem {
        // In all groups and all group items
        for (let group of this.options.groups) {
            for (let item of group.items) {
                // Match?
                if (itemName == item.name) {
                    return item;
                }
            }
        }

        // Not found?
        return null;
    }

    public select(itemName: string): void {
        let item = this.find(itemName);

        // Group not found?
        if (!item) {
            Log.w(`Unknown item ${itemName} to select in ${this.id}`);
            return;
        }

        // Already selected?
        if (item.selected) {
            return;
        }

        // Deselect all groups and items
        for (let group of this.options.groups) {
            for (let item of group.items) {
                item.selected = false;
            }
        }

        // Select current
        item.selected = true;

        // Redraw
        this.render();

        // OnItemSelect handler
        this.onSelect(item);
    }

}
