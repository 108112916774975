// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/assets/images/icons/v2/24/24-reload.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/assets/images/icons/v2/24/24-export.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("/assets/images/icons/v2/24/24-plus.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("/assets/images/icons/v2/24/24-close.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("/assets/images/icons/v2/20/20-minus-3.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `pinko-icon {
  cursor: pointer !important;
  user-select: none;
  transition: all 100ms cubic-bezier(0, 0, 0.38, 0.9) 0ms;
  transition-property: background-color;
  display: block;
  position: relative;
  border-radius: 4px;
}
pinko-icon:after {
  content: "";
  width: 24px;
  height: 24px;
  position: absolute;
  left: 4px;
  top: 4px;
  background-color: rgba(22, 22, 22, 0.5019607843);
}
pinko-icon:hover {
  background-color: rgba(22, 22, 22, 0.0392156863);
}
pinko-icon:hover:after {
  background-color: rgba(22, 22, 22, 0.5019607843);
}
pinko-icon[icon=Reload]:after {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
pinko-icon[icon=Export]:after {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
pinko-icon[icon=Add]:after {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}
pinko-icon[icon=Close]:after {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}
pinko-icon[icon=Remove]:after {
  background-color: #EB0D3F;
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
}
pinko-icon[size="24"] {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
}
pinko-icon[size="32"] {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
}`, "",{"version":3,"sources":["webpack://./src/pinko/components/pinko-icon/pinko-icon.scss","webpack://./src/pinko/styles/_mixins.scss","webpack://./src/pinko/styles/_colors.scss"],"names":[],"mappings":"AAIA;ECsDE,0BAAA;EACA,iBAAA;EAwBA,uDAAA;EACA,qCAAA;ED7EA,cAAA;EACA,kBAAA;EACA,kBAAA;AADF;AAGE;EACE,WAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,SAAA;EACA,QAAA;EACA,gDEckB;AFftB;AAIE;EACE,gDEdU;AFYd;AAII;EACE,gDEOgB;AFTtB;AAOI;EACE,mDAAA;AALN;AAUI;EACE,mDAAA;AARN;AAaI;EACE,mDAAA;AAXN;AAgBI;EACE,mDAAA;AAdN;AAmBI;EACE,yBEtDa;EFuDb,mDAAA;AAjBN;AAqBE;EChCA,WDiC6B;EChC7B,YDgCmC;EC/BnC,eD+B6B;EC9B7B,gBD8BmC;AAhBrC;AAmBE;ECpCA,WDqC6B;ECpC7B,YDoCmC;ECnCnC,eDmC6B;EClC7B,gBDkCmC;AAdrC","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
