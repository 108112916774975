// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `pinko-overlay {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(24, 24, 24, 0.1);
  align-items: center;
  justify-content: center;
  z-index: 99;
}`, "",{"version":3,"sources":["webpack://./src/pinko/components/pinko-overlay/pinko-overlay.scss","webpack://./src/runko/styles/_mixins.scss"],"names":[],"mappings":"AAIA;ECoCE,aAAA;EACA,sBAAA;EDnCA,kBAAA;EACA,OAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,uCAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;AAFF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
