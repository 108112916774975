import "./pinko-dropdown.scss";
import template from "./pinko-dropdown.hbs";
import { component, events } from "../../../hiyo/decorators.js";
import { PinkoComponent } from "../pinko-component/pinko-component.js";
import { Context } from "../../../hiyo/context.js";
import { DropdownGroup, DropdownItem, PinkoDropdownOptions } from "./types.js";
import { Log } from "../../../hiyo/log.js";

@component(template)
@events("click")
export class PinkoDropdown extends PinkoComponent<Context, PinkoDropdownOptions> {

    // Event handling methods
    public onSelect(item: DropdownItem, group?: DropdownGroup): void {};

    public onEvent(event: Event): void {
        // Remove if clicked outside and visible
        if (event.type == "click" && this.isConnected) {
            this.remove();
        }
    }

    public find(itemName: string): DropdownItem {
        // In all groups and all group items
        for (let group of this.options.groups) {
            for (let item of group.items) {
                // Match?
                if (itemName == item.name) {
                    return item;
                }
            }
        }

        // Not found?
        return null;
    }

    public show(pivot: HTMLElement, x?: number, y?: number): void {
        // Trigger element positions
        let rect = pivot ? pivot.getBoundingClientRect() : { left: x, top: y, width: 0, height: 0 };

        // Attach to dom
        document.body.appendChild(this);

        // Pivot point of the trigger
        let startX = 0;
        let startY = 0;

        // Get the trigger point
        if (this.options.start == "TopLeft") {
            startX = rect.left;
            startY = rect.top;
        }
        else if (this.options.start == "TopRight") {
            startX = rect.left + rect.width;
            startY = rect.top;
        }
        else if (this.options.start == "BottomLeft") {
            startX = rect.left;
            startY = rect.top +  + rect.height;
        }
        else {
            startX = rect.left + rect.width;
            startY = rect.top + rect.height;
        }

        // Menu position
        let left = 0;
        let top = 0;

        // Assign menu position
        if (this.options.anchor == "TopLeft") {
            left = startX + document.body.scrollLeft;
            top = startY + document.body.scrollTop;
        }
        else if (this.options.anchor == "TopRight") {
            left = startX - this.offsetWidth + document.body.scrollLeft;
            top = startY + document.body.scrollTop;
        }
        else if (this.options.anchor == "BottomLeft") {
            left = startX + document.body.scrollLeft;
            top = startY - this.offsetHeight + document.body.scrollTop;
        }
        else {
            left = startX - this.offsetWidth + document.body.scrollLeft;
            top = startY - this.offsetHeight + document.body.scrollTop;
        }

        // Move position by offset
        if (this.options.offset?.length == 2) {
            left += this.options.offset[0];
            top += this.options.offset[1];
        }

        // Set position to style
        this.style.left = `${left}px`;
        this.style.top = `${top}px`;
    }

    public select(itemName: string): void {
        let item = this.find(itemName);

        // Group not found?
        if (!item) {
            Log.w(`Unknown item ${itemName} to select in ${this.id}`);
            return;
        }

        // Already selected?
        if (item.selected) {
            return;
        }

        // Deselect all groups and items
        for (let group of this.options.groups) {
            for (let item of group.items) {
                item.selected = false;
            }
        }

        // Select current
        item.selected = true;

        // Remove self
        this.remove();

        // OnItemSelect handler
        this.onSelect(item);
    }

}
