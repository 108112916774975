import "./partner-create-dialog.scss";
import template from "./partner-create-dialog.hbs";
import { component, query } from "../../../hiyo/decorators.js";
import { PartnerCreateDialogOptions } from "./types.js";
import { PinkoDialog } from "../../../pinko/components/pinko-dialog/pinko-dialog.js";
import { Address, Partner } from "../../clients/api-client/types.js";
import { PartnerAddressDialog } from "../partner-address-dialog/partner-address-dialog.js";
import { PinkoOverlay } from "../../../pinko/components/pinko-overlay/pinko-overlay.js";
import { PinkoForm } from "../../../pinko/components/pinko-form/pinko-form.js";
import { IncinetContext } from "../../context/incinet-context.js";

@component(template)
export class PartnerCreateDialog extends PinkoDialog<IncinetContext, PartnerCreateDialogOptions> {

    // Properties
    public partner: Partner;

    // Child components
    @query("pinko-form") public form: PinkoForm;

    public onCreate() {
        // Empty partner
        this.partner = {
            _id: null,
            name: null,
            driveUrl: null,
            orgNo: null,
            vatNo: null,
            addresses: [],
            contacts: [],
            website: null,
            note: null,
            documents: [],
            created: null,
            archived: null,
            stream: [],
            tags: []
        }
    }

    public render(): void {
        // Get data to store them before render
        let data = this.form?.getData();

        // When render is called, all form data fields will be redrawn blank
        super.render();

        // Restore form data
        this.form.setData(data);
    }

    public addAddress(): void {
        // Address dialog
        let dialog = new PartnerAddressDialog(this.context);

        // Handle data
        dialog.onSubmit = (address: Address) => {
            // Add new address
            this.partner.addresses.push(address);

            // Redraw
            this.render();
        }

        // Show dialog
        dialog.appendTo(new PinkoOverlay().appendTo(document.body));
    }

    public removeAddress(i: number): void {
        // Remove address by index
        this.partner.addresses.splice(i, 1);

        // Redraw
        this.render();
    }

    public async submit(result?: any): Promise<void> {
        // Validation
        if (!this.form.validate()) {
            return;
        }

        // Show loader
        this.lock();

        // Merge partner data with form
        Object.assign(this.partner, this.form.getData());

        try {
            // API call
            await this.context.api.postResource("partners", this.partner);
        }
        catch (e) {
            if (e.status == 422) {
                //this.form.setValidationErrors(e.response);
                console.error(e.response);
                return;
            }
        }

        // Hide loader
        this.unlock();

        // Super call
        await super.submit(result);
    }

}