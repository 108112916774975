import "./pinko-filter.scss";
import template from "./pinko-filter.hbs";
import { component } from "../../../hiyo/decorators.js";
import { Component } from "../../../hiyo/component.js";
import { FilterItem, PinkoFilterOptions } from "./types.js";
import { Context } from "../../../hiyo/context.js";
import { PinkoSearch } from "../pinko-search/pinko-search.js";
import { PinkoField } from "../pinko-field/pinko-field.js";

@component(template)
export class PinkoFilter extends Component<Context, PinkoFilterOptions> {

    // Components
    public search: PinkoSearch;

    // Event handling methods
    public onSearch(data: any): void {};
    public onItemSelect(item: FilterItem): void {};

    public onCreate() {
        // Create components
        this.search = new PinkoSearch(this.context, {
            name: "search"
        });

        // Delegate search to handler
        this.search.onSubmit = () => {
            this.onSearch(this.getData());
        }
    }

    public onRender(): void {
        // Append filter fields
        for (let select of this.options.selects) {
            // Add to dom
            select.appendTo(this.querySelector("div.selects"));

            // Delegate search to handler
            select.onSubmit = () => {
                this.onSearch(this.getData());
            }
        }
    }

    public selectItem(i: number): void {
        // Get item
        let item = this.options.items[i];

        // OnItemSelect handler
        this.onItemSelect(item);
    }

    public getData(): any {
        let data = {};

        // Find all field elements
        this.querySelectorAll("*").forEach((element: Element) => {
            // Field component?
            if (element instanceof PinkoField) {
                // Field has data?
                if (element.options.value) {
                    data[element.options.name] = element.options.value;
                }
            }
        });

        return data;
    }

}