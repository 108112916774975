import "./pinko-form.scss";
import template from "./pinko-form.hbs";
import { Context } from "../../../hiyo/context.js";
import { PinkoComponent } from "../pinko-component/pinko-component.js";
import { component } from "../../../hiyo/decorators.js";
import { DataFormOptions } from "./types.js";
import { PinkoField } from "../pinko-field/pinko-field.js";

@component(template)
export class PinkoForm<T extends Context = Context> extends PinkoComponent<T, DataFormOptions> {

    // Event handling methods
    public onSubmit(data: any): void {};

    private getInputs(): PinkoField[] {
        // Get all DataInput elements
        let inputs: PinkoField[] = [];

        // Get through all nodes
        this.querySelectorAll("*").forEach((element) => {
            // Is DataInput element?
            if (element instanceof PinkoField) {
                inputs.push(<PinkoField>element);
            }
        });

        return inputs;
    }

    public getData(): any {
        // Data result
        let result: any = {};

        // Validate all found elements
        for (let input of this.getInputs()) {
            // Avoid undefined values
            if (input.options.value != null) {
                // Object?
                if (typeof input.options.value == "object") {
                    result[input.options.name] = Object.values(input.options.value).join(",");
                }
                // String, number or boolean
                else {
                    result[input.options.name] = input.options.value;
                }
            }
        }

        return result;
    }

    public setData(data: any): any {
        // No data?
        if (data == null) {
            return;
        }

        // Validate all found elements
        for (let input of this.getInputs()) {
            // Avoid undefined values
            if (data[input.options.name] != null) {
                input.setValue(data[input.options.name]);
            }
        }
    }

    public setReason(name: string, reason: string): void {
        // Find input by name
        let input = this.getInputs().find(x => x.options.name == name);

        // Found?
        if (input) {
            input.setReason(reason);
        }
    }

    public validate(): boolean {
        // Validation result
        let result = true;

        // Validate all found elements
        for (let input of this.getInputs()) {
            result = input.validate() && result;
        }

        return result;
    }

    public submit(): void {
        // Valida te form
        if (!this.validate()) {
            return;
        }

        // OnSubmit handler
        this.onSubmit(this.getData());
    }

}