// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `partners-browser {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #FFFFFF;
  padding: 24px;
  border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/incinet/components/partners-browser/partners-browser.scss","webpack://./src/pinko/styles/_mixins.scss","webpack://./src/pinko/styles/_colors.scss"],"names":[],"mappings":"AAGA;EC8CE,YAAA;EATA,aAAA;EACA,sBAAA;EDnCA,sBAAA;EACA,yBEyDY;EFxDZ,aAAA;EACA,kBAAA;AADF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
