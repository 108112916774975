// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `partner-preview {
  width: 520px;
}`, "",{"version":3,"sources":["webpack://./src/incinet/components/partner-preview/partner-preview.scss"],"names":[],"mappings":"AAIA;EACE,YAAA;AAHF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
