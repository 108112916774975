// Create component map to register decorator
// Decorators are not executed on import statement
import { PinkoTable } from "../pinko/components/pinko-table/pinko-table.js";
import { PinkoInput } from "../pinko/components/pinko-input/pinko-input.js";
import { PinkoForm } from "../pinko/components/pinko-form/pinko-form.js";
import { PinkoButton } from "../pinko/components/pinko-button/pinko-button.js";
import { IncinetApp } from "./components/incinet-app/incinet-app.js";
import { MainMenu } from "./components/main-menu/main-menu.js";
import { UserBrowser } from "./components/user-browser/user-browser.js";
import { LoginPage } from "./components/login-page/login-page.js";
import { PartnersBrowser } from "./components/partners-browser/partners-browser.js";
import { PinkoFilter } from "../pinko/components/pinko-filter/pinko-filter.js";
import { PinkoIcon } from "../pinko/components/pinko-icon/pinko-icon.js";
import { PinkoTag } from "../pinko/components/pinko-tag/pinko-tag.js";
import { PinkoSearch } from "../pinko/components/pinko-search/pinko-search.js";
import { PinkoAction } from "../pinko/components/pinko-action/pinko-action.js";
import { PinkoSelect } from "../pinko/components/pinko-select/pinko-select.js";
import { PinkoPreview } from "../pinko/components/pinko-preview/pinko-preview.js";

export const DEFINE_MAP: any = {
    PinkoTable,
    PinkoInput,
    PinkoSelect,
    PinkoForm,
    PinkoButton,
    PinkoFilter,
    PinkoIcon,
    PinkoTag,
    PinkoSearch,
    PinkoAction,
    PinkoPreview,
    IncinetApp,
    MainMenu,
    UserBrowser,
    LoginPage,
    PartnersBrowser
}

// Create app component end attach to DOM
// Erase previously displayed loader
document.body.innerHTML = "<incinet-app></incinet-app>";