// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/assets/images/icons/tools/24/24-tools-search.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `pinko-search {
  display: block;
  position: relative;
  margin-bottom: 12px;
}
pinko-search:before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  left: 12px;
  top: 8px;
  background-color: rgba(31, 31, 31, 0.6980392157);
  mask-size: 24px 24px;
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  pointer-events: none;
}
pinko-search label {
  font-family: "Incinet Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  color: #1F1F1F;
}
pinko-search input {
  font-family: "Incinet Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  transition: all 100ms cubic-bezier(0, 0, 0.38, 0.9) 0ms;
  transition-property: border-color;
  display: block;
  width: 100%;
  height: 40px;
  outline: none;
  box-sizing: border-box;
  color: #1F1F1F;
  background-color: #FFFFFF;
  padding: 8px 12px 8px 40px;
  margin: 4px 0;
  border: 1px solid rgba(111, 111, 111, 0.2);
  border-radius: 8px;
}
pinko-search input:focus {
  border-color: #8000F7;
}
pinko-search input:-webkit-autofill, pinko-search input:-webkit-autofill:hover, pinko-search input:-webkit-autofill:focus, pinko-search input:-webkit-autofill:active {
  -webkit-text-fill-color: #1F1F1F;
  -webkit-box-shadow: 0 0 0 1000px #FFFFFF inset;
}
pinko-search div.description {
  font-family: "Incinet Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  color: rgba(22, 22, 22, 0.5019607843);
}
pinko-search div.reason {
  font-family: "Incinet Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  position: absolute;
  color: #EB0D3F;
}
pinko-search[invalid=true] input {
  border-color: #EB0D3F;
}`, "",{"version":3,"sources":["webpack://./src/pinko/components/pinko-search/pinko-search.scss","webpack://./src/pinko/styles/_colors.scss","webpack://./src/pinko/styles/_mixins.scss","webpack://./src/pinko/styles/_styles.scss"],"names":[],"mappings":"AAIA;EACE,cAAA;EACA,kBAAA;EACA,mBAAA;AAHF;AAKE;EACE,WAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,QAAA;EACA,gDCemB;EDdnB,oBAAA;EACA,mDAAA;EACA,oBAAA;AAHJ;AAME;EErBA,0CAAA;ECIA,eAAA;EACA,kBAAA;EACA,mBAAA;EHiBE,cCNiB;ADKrB;AAIE;EEtBA,yCAAA;ECmGA,eAAA;EACA,kBAAA;EACA,mBAAA;EDvBA,uDAAA;EACA,iCAAA;EFtDE,cAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,cCjBiB;EDkBjB,yBC4BU;ED3BV,0BAAA;EACA,aAAA;EACA,0CAAA;EACA,kBAAA;AAEJ;AAAI;EACE,qBCWqB;ADT3B;AACI;EAIE,gCChCe;EDiCf,8CAAA;AAFN;AAOE;EEvDA,0CAAA;ECIA,eAAA;EACA,kBAAA;EACA,mBAAA;EHmDE,qCCtCkB;ADoCtB;AAKE;EE5DA,0CAAA;ECIA,eAAA;EACA,kBAAA;EACA,mBAAA;EHwDE,kBAAA;EACA,cC/De;AD+DnB;AAII;EACE,qBCpEa;ADkEnB","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
