// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/assets/images/icons/tools/16/16-tools-dropdown_arrow.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `pinko-select {
  display: block;
  position: relative;
}
pinko-select label {
  font-family: "Incinet Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  color: #1F1F1F;
  margin-bottom: 4px;
}
pinko-select input {
  cursor: pointer !important;
  user-select: none;
  font-family: "Incinet Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  transition: all 100ms cubic-bezier(0, 0, 0.38, 0.9) 0ms;
  transition-property: border-color;
  display: block;
  width: 100%;
  height: 40px;
  outline: none;
  box-sizing: border-box;
  color: #1F1F1F;
  background-color: #FFFFFF;
  padding: 8px 40px 8px 12px;
  border-radius: 4px;
  border: 1px solid rgba(111, 111, 111, 0.2);
}
pinko-select input::placeholder {
  color: rgba(22, 22, 22, 0.5019607843);
}
pinko-select div.description {
  font-family: "Incinet Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  color: rgba(22, 22, 22, 0.5019607843);
}
pinko-select div.reason {
  font-family: "Incinet Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  position: absolute;
  color: #EB0D3F;
  margin-top: 4px;
}
pinko-select[type=Input] {
  margin-bottom: 24px;
}
pinko-select[type=Input]:after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 12px;
  right: 16px;
  background-color: rgba(22, 22, 22, 0.5019607843);
  mask-size: 16px 16px;
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  pointer-events: none;
}
pinko-select[borderless=true] input {
  border-color: #FFFFFF;
}
pinko-select[invalid=true] input {
  border-color: #EB0D3F !important;
}`, "",{"version":3,"sources":["webpack://./src/pinko/components/pinko-select/pinko-select.scss","webpack://./src/pinko/styles/_mixins.scss","webpack://./src/pinko/styles/_styles.scss","webpack://./src/pinko/styles/_colors.scss"],"names":[],"mappings":"AAIA;EACE,cAAA;EACA,kBAAA;AAHF;AAKE;ECPA,0CAAA;ECIA,eAAA;EACA,kBAAA;EACA,mBAAA;EFGE,cGQiB;EHPjB,kBAAA;AAAJ;AAGE;EC4CA,0BAAA;EACA,iBAAA;EA1DA,0CAAA;ECkBA,eAAA;EACA,kBAAA;EACA,mBAAA;ED8DA,uDAAA;EACA,iCAAA;EDlEE,cAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,cGLiB;EHMjB,yBGwCU;EHvCV,0BAAA;EACA,kBAAA;EACA,0CAAA;AAIJ;AAFI;EACE,qCGVgB;AHctB;AAAE;ECjCA,0CAAA;ECIA,eAAA;EACA,kBAAA;EACA,mBAAA;EF6BE,qCGhBkB;AHqBtB;AAFE;ECtCA,0CAAA;ECIA,eAAA;EACA,kBAAA;EACA,mBAAA;EFkCE,kBAAA;EACA,cGzCe;EH0Cf,eAAA;AAOJ;AAJE;EACE,mBAAA;AAMJ;AAJI;EACE,WAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,SAAA;EACA,WAAA;EACA,gDGxBgB;EHyBhB,oBAAA;EACA,mDAAA;EACA,oBAAA;AAMN;AADI;EACE,qBGDQ;AHId;AAEI;EACE,gCAAA;AAAN","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
