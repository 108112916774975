import "./partner-browser.scss";
import template from "./partner-browser.hbs";
import { component } from "../../../hiyo/decorators.js";
import { IncinetContext } from "../../context/incinet-context.js";
import { PartnerBrowserOptions } from "./types.js";
import { PinkoFilter } from "../../../pinko/components/pinko-filter/pinko-filter.js";
import { PinkoSelect } from "../../../pinko/components/pinko-select/pinko-select.js";
import { FilterItem } from "../../../pinko/components/pinko-filter/types.js";
import { PinkoTable } from "../../../pinko/components/pinko-table/pinko-table.js";
import { PinkoComponent } from "../../../pinko/components/pinko-component/pinko-component.js";
import { ObjectHelper } from "../../../hiyo/object-helper.js";
import { TableColumn, TableRow } from "../../../pinko/components/pinko-table/types.js";
import { PinkoPreview } from "../../../pinko/components/pinko-preview/pinko-preview.js";
import { PartnerPreview } from "../partner-preview/partner-preview.js";
import { PinkoOverlay } from "../../../pinko/components/pinko-overlay/pinko-overlay.js";
import { PartnerCreateDialog } from "../partner-create-dialog/partner-create-dialog.js";

export const PAGE_SIZE = 30;

@component(template, true)
export class PartnerBrowser extends PinkoComponent<IncinetContext, PartnerBrowserOptions> {

    // Properties
    public page: number = 1;

    // Components
    public filter: PinkoFilter;
    public table: PinkoTable;
    public preview: PinkoPreview;

    public onCreate() {
        // Create all components
        this.createFilter();
        this.createTable();
        this.createPreview();
    }

    public onDetach(): void {
        // Remove preview if connected
        this.preview?.remove();
    }

    public createFilter(): void {
        // Create filter
        this.filter = new PinkoFilter(this.context, {
            title: "components.PartnerBrowser.title",
            path: ["Company", "Partners"],
            items: [
                {
                    name: "Add",
                    icon: "Add",
                },
                {
                    name: "Reload",
                    icon: "Reload",
                }
            ],
            selects: [
                new PinkoSelect(this.context, {
                    type: "Tag",
                    name: "addresses.country",
                    placeholder: "placeholders.country",
                    autosubmit: true,
                    items: [
                        {
                            name: "Iraq",
                            label: "Iraq"
                        },
                        {
                            name: "Austria",
                            label: "Austria"
                        },
                        {
                            name: "Tunisia",
                            label: "Tunisia"
                        }
                    ]
                })
            ]
        });

        // Reload table
        this.filter.onSearch = async () => {
            await this.load();
        }

        // Reload selected
        this.filter.onItemSelect = async (item: FilterItem) => {
            // Reload?
            if (item.name == "Reload") {
                await this.load();
            }

            // Add?
            if (item.name == "Add") {
                await this.openNew();
            }
        }
    }

    public createTable(): void {
        // Create table
        this.table = new PinkoTable(this.context, {
            type: "SingleSelect",
            size: "Short",
            //height: "100%",
            rows: {
                id: "_id",
                decorator: (data: any): string => {
                    return data.disabled ? "disabled" : null;
                }
            },
            columns: [
                {
                    name: "name",
                    type: "String",
                    property: "name",
                    label: "columns.name",
                    width: 280,
                    sortable: true,
                    selected: true
                },
                {
                    name: "addresses.country",
                    type: "String",
                    property: (data: any) => {
                        return data.addresses?.find(x => x.type == "Legal")?.country
                    },
                    label: "columns.country",
                    width: 120,
                    ellipsis: true,
                    sortable: true
                },
                {
                    name: "note",
                    type: "String",
                    property: "note",
                    label: "columns.note",
                    minWidth: 280,
                    sortable: true
                }
            ]
        });

        // Reload on sort
        this.table.onColumnSelect = async (column: TableColumn) => {
            // Force load
            await this.load();
        }

        // OnRowSelect handle
        this.table.onRowSelect = async (row: TableRow) => {
            await this.openPreview(row.data);
        }

        // OnRowSelect handle
        this.table.onRowOpen = async (row: TableRow) => {
            alert("TODO: Open detail");
        }

    }

    public createPreview(): void {
        // Create preview
        this.preview = new PartnerPreview(this.context);

        // Deselect on detach
        this.preview.onDetach = () => {
            this.table.unselectRow(this.preview.options.data._id);
        }
    }

    protected async openNew(): Promise<void> {
        // Create dialog
        let dialog = new PartnerCreateDialog(this.context);

        // Reload table on submit
        dialog.onSubmit = async () => {
            await this.load();
        }

        dialog.appendTo(new PinkoOverlay().appendTo(document.body));
    }

    protected async openPreview(data: any): Promise<void> {
        // Assign data
        this.preview.options.data = data;

        // Clear element content
        this.preview.clear();

        // Reload data aif already connected
        if (this.preview.isConnected) {
            await this.preview.reload();
        }
        // Attach if closed
        else {
            this.preview.appendTo(document.body.querySelector("aside"));
        }

        // Select data
        this.table.selectRow(this.preview.options.data?._id);
    }

    async load(): Promise<void> {
        // Lock control
        await this.lock();

        // Data from filter
        let data = this.filter.getData();

        // Url with host
        let url = new URL(`${this.context.api.options.host}/partners`);

        // Pagination
        url.searchParams.set("pageSize", `${PAGE_SIZE}`);
        url.searchParams.set("page", `${this.page}`);

        // Add sort by selected column
        let column = this.table.options.columns.find(x => x.selected);
        if (column) {
            // Sort parameter
            let sort = `${column.name}:${column.descendent ? "desc" : "asc"}`;

            // Second sort condition enabled?
            if (column.extraSort) {
                sort += `,${column.extraSort}`;
            }

            // Set final sort parameter
            url.searchParams.set("sort", sort);
        }

        // Query building
        if (data) {
            for (let key of Object.keys(data)) {
                let value = data[key];

                // Skip null value
                if (value == null) {
                    continue;
                }

                // Comma separated values
                else if (typeof value == "object") {
                    url.searchParams.set(key, ObjectHelper.toCommaKeys(value));
                }
                // String value
                else {
                    url.searchParams.set(key, value);
                }
            }
        }

        //await SystemHelper.sleep(2000);
        // Get clients
        let resource = await this.context.api.getResource(`${url.pathname}${url.search}`);

        // Set data to table
        this.table.setData(resource.data);

        // Unlock control
        await this.unlock();
    }
}