// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `partner-create-dialog {
  width: 996px !important;
  height: calc(100vh - 240px);
}`, "",{"version":3,"sources":["webpack://./src/incinet/components/partner-create-dialog/partner-create-dialog.scss"],"names":[],"mappings":"AAGA;EACE,uBAAA;EACA,2BAAA;AAFF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
