// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `incinet-app {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  background-color: rgba(22, 22, 22, 0.0588235294);
}
incinet-app > nav {
  padding: 12px;
}
incinet-app > main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 12px 12px 12px 0;
}`, "",{"version":3,"sources":["webpack://./src/incinet/components/incinet-app/incinet-app.scss","webpack://./src/pinko/styles/_mixins.scss","webpack://./src/pinko/styles/_colors.scss"],"names":[],"mappings":"AAGA;EC8CE,YAAA;EAdA,aAAA;EACA,mBAAA;ED9BA,gDEEY;AFHd;AAGE;EACE,aAAA;AADJ;AAIE;ECqCA,YAAA;EATA,aAAA;EACA,sBAAA;ED1BE,yBAAA;AADJ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
