// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/assets/images/logo/20/invipo_logo_in_20_w.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/assets/images/icons/v2/24/24-insights.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("/assets/images/icons/v2/24/24-home.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("/assets/images/icons/v2/24/24-access.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("/assets/images/icons/v2/24/24-payment-coins.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("/assets/images/icons/v2/24/24-properties.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("/assets/images/icons/v2/24/24-settings.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("/assets/images/icons/v2/24/24-user.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `main-menu, .main-menu {
  display: flex;
  flex-direction: column;
  width: 56px;
  height: 100%;
  min-width: 56px;
  min-height: 100%;
  background-color: #010101;
  border-radius: 8px;
}
main-menu div.logo, .main-menu div.logo {
  width: 48px;
  height: 48px;
  background-color: #FFFFFF;
  margin: 8px 4px;
  mask-position: 8px 14px;
  mask-size: 32px 20px;
  mask-repeat: no-repeat;
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
main-menu div.group, .main-menu div.group {
  cursor: pointer !important;
  user-select: none;
  width: 48px;
  height: 40px;
  border-radius: 3px;
  margin: 4px 4px;
}
main-menu div.group:hover, main-menu div.group-selected, .main-menu div.group:hover, .main-menu div.group-selected {
  background-color: rgba(255, 255, 255, 0.1411764706);
}
main-menu div.group:hover div.icon, main-menu div.group-selected div.icon, .main-menu div.group:hover div.icon, .main-menu div.group-selected div.icon {
  background-color: #FFFFFF !important;
}
main-menu div.group-settings, .main-menu div.group-settings {
  margin-top: auto;
}
main-menu div.group-user, .main-menu div.group-user {
  margin-bottom: 12px;
}
main-menu div.group div.icon, .main-menu div.group div.icon {
  width: 24px;
  height: 24px;
  margin: 8px 12px;
  background-color: rgba(255, 255, 255, 0.5019607843);
  mask-size: 24px;
}
main-menu div.group div.icon-insights, .main-menu div.group div.icon-insights {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
main-menu div.group div.icon-company, .main-menu div.group div.icon-company {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}
main-menu div.group div.icon-management, .main-menu div.group div.icon-management {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}
main-menu div.group div.icon-sales, .main-menu div.group div.icon-sales {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
}
main-menu div.group div.icon-delivery, .main-menu div.group div.icon-delivery {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_5___});
}
main-menu div.group div.icon-settings, .main-menu div.group div.icon-settings {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_6___});
}
main-menu div.group div.icon-user, .main-menu div.group div.icon-user {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_7___});
}`, "",{"version":3,"sources":["webpack://./src/incinet/components/main-menu/main-menu.scss","webpack://./src/pinko/styles/_mixins.scss","webpack://./src/pinko/styles/_colors.scss"],"names":[],"mappings":"AAGA;ECqCE,aAAA;EACA,sBAAA;EAbA,WDvB2B;ECwB3B,YDxBiC;ECyBjC,eDzB2B;EC0B3B,gBD1BiC;EACjC,yBEsEc;EFrEd,kBAAA;AAEF;AAAE;EACE,WAAA;EACA,YAAA;EACA,yBEoDU;EFnDV,eAAA;EACA,uBAAA;EACA,oBAAA;EACA,sBAAA;EACA,mDAAA;AAEJ;AACE;ECsCA,0BAAA;EACA,iBAAA;EDrCE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AAEJ;AAAI;EAEE,mDAAA;AACN;AACM;EACE,oCAAA;AACR;AAGI;EACE,gBAAA;AADN;AAII;EACE,mBAAA;AAFN;AAKI;EACE,WAAA;EACA,YAAA;EACA,gBAAA;EACA,mDAAA;EACA,eAAA;AAHN;AAKM;EACE,mDAAA;AAHR;AAMM;EACE,mDAAA;AAJR;AAOM;EACE,mDAAA;AALR;AAQM;EACE,mDAAA;AANR;AASM;EACE,mDAAA;AAPR;AAUM;EACE,mDAAA;AARR;AAWM;EACE,mDAAA;AATR","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
