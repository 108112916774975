// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/assets/incinet/1200-incinity-white.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/assets/incinet/background-01.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("/assets/incinet/background-02.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("/assets/incinet/background-03.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("/assets/incinet/background-04.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("/assets/incinet/background-05.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("/assets/incinet/background-06.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("/assets/incinet/background-07.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("/assets/incinet/background-08.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("/assets/incinet/background-09.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `login-page {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: keyframes-fade-in 600ms cubic-bezier(0, 0, 0.38, 0.9) 0ms;
  background-color: #F00544;
  background-size: cover;
}
login-page:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #F00544;
  opacity: 0.7;
  z-index: 1;
}
login-page div.dialog {
  animation: keyframes-grow-in 300ms cubic-bezier(0, 0, 0.38, 0.9) 0ms;
  transition: all 100ms cubic-bezier(0, 0, 0.38, 0.9) 0ms;
  transition-property: opacity;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  position: relative;
  width: 450px;
  background-color: #FFFFFF;
  box-sizing: border-box;
  padding: 24px;
  border-radius: 6px;
  z-index: 2;
}
login-page div.dialog div.logo {
  position: absolute;
  width: 220px;
  height: 80px;
  left: 0;
  top: -96px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: 220px 80px;
  background-repeat: no-repeat;
}
login-page div.dialog div.form {
  margin-bottom: 32px;
}
login-page div.dialog div.reset {
  font-family: "Incinet Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
}
login-page.background-1 {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
login-page.background-2 {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}
login-page.background-3 {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}
login-page.background-4 {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
}
login-page.background-5 {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_5___});
}
login-page.background-6 {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_6___});
}
login-page.background-7 {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_7___});
}
login-page.background-8 {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_8___});
}
login-page.background-9 {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_9___});
}`, "",{"version":3,"sources":["webpack://./src/incinet/components/login-page/login-page.scss","webpack://./src/pinko/styles/_mixins.scss","webpack://./src/pinko/styles/_colors.scss","webpack://./src/pinko/styles/_styles.scss"],"names":[],"mappings":"AAIA;EC6CE,YAAA;EA4BA,aAAA;EACA,uBAAA;EACA,mBAAA;EASA,oEAAA;EDhFA,yBAAA;EACA,sBAAA;AADF;AAGE;EACE,WAAA;EACA,kBAAA;EACA,OAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,yBAAA;EACA,YAAA;EACA,UAAA;AADJ;AAIE;ECiEA,oEAAA;EALA,uDAAA;EACA,4BAAA;EAQA,2CAAA;EDjEE,kBAAA;EACA,YAAA;EACA,yBEmCU;EFlCV,sBAAA;EACA,aAAA;EACA,kBAAA;EACA,UAAA;AADJ;AAGI;EACE,kBAAA;EACA,YAAA;EACA,YAAA;EACA,OAAA;EACA,UAAA;EACA,yDAAA;EACA,2BAAA;EACA,4BAAA;AADN;AAII;EACE,mBAAA;AAFN;AAKI;ECjDF,0CAAA;EEkBA,eAAA;EACA,kBAAA;EACA,mBAAA;AH8BF;AAIE;EACE,yDAAA;AAFJ;AAKE;EACE,yDAAA;AAHJ;AAME;EACE,yDAAA;AAJJ;AAOE;EACE,yDAAA;AALJ;AAQE;EACE,yDAAA;AANJ;AASE;EACE,yDAAA;AAPJ;AAUE;EACE,yDAAA;AARJ;AAWE;EACE,yDAAA;AATJ;AAYE;EACE,yDAAA;AAVJ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
