import "./partner-address-dialog.scss";
import template from "./partner-address-dialog.hbs";
import { component, query } from "../../../hiyo/decorators.js";
import { PartnerAddressDialogOptions } from "./types.js";
import { PinkoDialog } from "../../../pinko/components/pinko-dialog/pinko-dialog.js";
import { PinkoForm } from "../../../pinko/components/pinko-form/pinko-form.js";
import { IncinetContext } from "../../context/incinet-context";

@component(template)
export class PartnerAddressDialog extends PinkoDialog<IncinetContext, PartnerAddressDialogOptions> {

    // Child components
    @query("pinko-form") public form: PinkoForm;

    public async submit(result?: any): Promise<void> {
        // Validation
        if (!this.form.validate()) {
            return;
        }

        // Super call with form data
        await super.submit(this.form.getData());
    }
}