import "./main-menu.scss";
import template from "./main-menu.hbs";
import { component } from "../../../hiyo/decorators.js";
import { Component } from "../../../hiyo/component.js";
import { IncinetContext } from "../../context/incinet-context.js";
import { MainMenuOptions } from "./types.js";
import { PinkoSidebar } from "../../../pinko/components/pinko-sidebar/pinko-sidebar.js";
import { SidebarItem } from "../../../pinko/components/pinko-sidebar/types";
import { StringHelper } from "../../../hiyo/string-helper.js";

@component(template)
export class MainMenu extends Component<IncinetContext, MainMenuOptions> {

    // Components
    public sidebar: PinkoSidebar;

    public onCreate() {
        // Create all components
        this.createSidebar();
    }

    public createSidebar(): void {
        // Create sidebar
        this.sidebar = new PinkoSidebar(this.context, {
            groups: [
                {
                    name: "Insights",
                    //label: "Insights",
                    items: [
                        {
                            name: "Cockpit",
                            label: "components.MainMenu.cockpit",
                            icon: "Cockpit",
                            separated: true
                        }
                    ]
                },
                {
                    name: "Company",
                    //label: "Company",
                    items: [
                        {
                            name: "PartnerBrowser",
                            label: "components.MainMenu.partners",
                            icon: "Partners"
                        },
                        {
                            name: "ContractBrowser",
                            label: "components.MainMenu.contracts",
                            icon: "Contracts"
                        },
                        {
                            name: "CalculationBrowser",
                            label: "components.MainMenu.calculations",
                            icon: "Calculations"
                        },
                        {
                            name: "OfferBrowser",
                            label: "components.MainMenu.offers",
                            icon: "Offers",
                            separated: true
                        }
                    ]
                },
                {
                    name: "Management",
                    //label: "Company",
                    items: [
                        {
                            name: "TaskBrowser",
                            label: "components.MainMenu.tasks",
                            icon: "Tasks",
                            separated: true
                        }
                    ]
                },
                {
                    name: "Settings",
                    //label: "Settings",
                    items: [
                        {
                            name: "UserBrowser",
                            label: "components.MainMenu.users",
                            icon: "Users"
                        },
                        {
                            name: "Logout",
                            label: "components.MainMenu.logout",
                            icon: "Logout"
                        }
                    ]

                }
            ]
        });

        // Set content
        this.sidebar.onSelect = (item: SidebarItem) => {
            // Logout?
            if (item.name == "Logout") {
                this.fire("logout");
                return;
            }

            // Get component name
            let component = StringHelper.toKebabCase(item.name);

            // Add component to page
            document.body.querySelector("incinet-app > main").innerHTML = `<${component}></${component}>`;
        }
    }

    public onRender() {
        // Auto select
        this.sidebar.select("PartnerBrowser");
    }
}