// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/assets/images/icons/v2/24/24-reload.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/assets/images/icons/v2/24/24-export.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `pinko-icon {
  cursor: pointer !important;
  user-select: none;
  transition: all 100ms cubic-bezier(0, 0, 0.38, 0.9) 0ms;
  transition-property: background-color;
  display: block;
  position: relative;
  border-radius: 4px;
}
pinko-icon:after {
  content: "";
  width: 24px;
  height: 24px;
  position: absolute;
  left: 4px;
  top: 4px;
  background-color: rgba(31, 31, 31, 0.6980392157);
}
pinko-icon:hover {
  background-color: rgba(22, 22, 22, 0.0588235294);
}
pinko-icon:hover:after {
  background-color: rgba(31, 31, 31, 0.6980392157);
}
pinko-icon[icon=Reload]:after {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
pinko-icon[icon=Export]:after {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
pinko-icon[size="24"] {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
}
pinko-icon[size="32"] {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
}`, "",{"version":3,"sources":["webpack://./src/pinko/components/pinko-icon/pinko-icon.scss","webpack://./src/pinko/styles/_mixins.scss","webpack://./src/pinko/styles/_colors.scss"],"names":[],"mappings":"AAIA;ECsDE,0BAAA;EACA,iBAAA;EAwBA,uDAAA;EACA,qCAAA;ED7EA,cAAA;EACA,kBAAA;EACA,kBAAA;AADF;AAGE;EACE,WAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,SAAA;EACA,QAAA;EACA,gDEamB;AFdvB;AAIE;EACE,gDEdU;AFYd;AAII;EACE,gDEMiB;AFRvB;AAOI;EACE,mDAAA;AALN;AAUI;EACE,mDAAA;AARN;AAYE;ECbA,WDc6B;ECb7B,YDamC;ECZnC,eDY6B;ECX7B,gBDWmC;AAPrC;AAUE;ECjBA,WDkB6B;ECjB7B,YDiBmC;EChBnC,eDgB6B;ECf7B,gBDemC;AALrC","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
