// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/assets/images/icons/v2/24/24-dashboard-02.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/assets/images/icons/v2/24/24-charging-ccs-sae.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("/assets/images/icons/v2/24/24-archive.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("/assets/images/icons/v2/24/24-calendar.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("/assets/images/icons/v2/24/24-halflist.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("/assets/images/icons/v2/24/24-ticket.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("/assets/images/icons/v2/24/24-user.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("/assets/images/icons/v2/24/24-export.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `pinko-sidebar {
  width: 256px;
  user-select: none;
}
pinko-sidebar div.group {
  display: flex;
  flex-direction: row;
  height: 48px;
  align-items: center;
  padding: 0 16px;
}
pinko-sidebar div.group div.label {
  font-family: "Incinet Semi Bold", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  color: rgba(22, 22, 22, 0.5019607843);
}
pinko-sidebar div.item {
  display: flex;
  flex-direction: row;
  cursor: pointer !important;
  user-select: none;
  height: 40px;
  align-items: center;
  gap: 8px;
  padding: 0 16px;
  border-radius: 8px;
}
pinko-sidebar div.item:hover {
  background-color: rgba(22, 22, 22, 0.0392156863);
}
pinko-sidebar div.item div.icon {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  background-color: rgba(22, 22, 22, 0.5019607843);
}
pinko-sidebar div.item div.icon-cockpit {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
pinko-sidebar div.item div.icon-partners {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
pinko-sidebar div.item div.icon-contracts {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}
pinko-sidebar div.item div.icon-calculations {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}
pinko-sidebar div.item div.icon-offers {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
}
pinko-sidebar div.item div.icon-tasks {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_5___});
}
pinko-sidebar div.item div.icon-users {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_6___});
}
pinko-sidebar div.item div.icon-logout {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_7___});
}
pinko-sidebar div.item div.label {
  font-family: "Incinet Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  color: #1F1F1F;
}
pinko-sidebar div.item-selected {
  background-color: rgba(128, 0, 247, 0.3019607843) !important;
}
pinko-sidebar div.item-separated {
  margin-bottom: 16px;
}
@media only screen and (max-width: 1280px) {
  pinko-sidebar {
    width: 160px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pinko/components/pinko-sidebar/pinko-sidebar.scss","webpack://./src/pinko/styles/_mixins.scss","webpack://./src/pinko/styles/_styles.scss","webpack://./src/pinko/styles/_colors.scss"],"names":[],"mappings":"AAKA;EACE,YAAA;EACA,iBAAA;AAJF;AAME;EC0BA,aAAA;EACA,mBAAA;EDzBE,YAAA;EACA,mBAAA;EACA,eAAA;AAHJ;AAKI;ECNF,4CAAA;ECsKA,eAAA;EACA,kBAAA;EACA,mBAAA;EFhKI,qCGGgB;AHHtB;AAIE;ECcA,aAAA;EACA,mBAAA;EAsBA,0BAAA;EACA,iBAAA;EDnCE,YAAA;EACA,mBAAA;EACA,QAAA;EACA,eAAA;EACA,kBAAA;AAAJ;AAEI;EACE,gDGvBQ;AHuBd;AAGI;ECNF,WDO+B;ECN/B,YDMqC;ECLrC,eDK+B;ECJ/B,gBDIqC;EACjC,gDGJgB;AHMtB;AAAM;EACE,mDAAA;AAER;AACM;EACE,mDAAA;AACR;AAEM;EACE,mDAAA;AAAR;AAGM;EACE,mDAAA;AADR;AAIM;EACE,mDAAA;AAFR;AAKM;EACE,mDAAA;AAHR;AAMM;EACE,mDAAA;AAJR;AAOM;EACE,mDAAA;AALR;AASI;ECtEF,0CAAA;ECkBA,eAAA;EACA,kBAAA;EACA,mBAAA;EFoDI,cGvDe;AHmDrB;AAOI;EACE,4DAAA;AALN;AAQI;EACE,mBAAA;AANN;AAUE;EAhFF;IAiFI,YAAA;EAPF;AACF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
