// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `pinko-form {
  display: block;
  margin: 0 auto;
}
pinko-form pinko-input,
pinko-form pinko-select {
  flex-grow: 1;
}
pinko-form[width=Short] {
  width: 640px;
}
pinko-form[width=Medium] {
  width: 720px;
}
pinko-form[width=Long] {
  width: 980px;
}`, "",{"version":3,"sources":["webpack://./src/pinko/components/pinko-form/pinko-form.scss","webpack://./src/pinko/styles/_mixins.scss"],"names":[],"mappings":"AAIA;EACE,cAAA;EACA,cAAA;AAHF;AAKE;;ECyCA,YAAA;AD1CF;AAME;EACE,YAAA;AAJJ;AAOE;EACE,YAAA;AALJ;AAQE;EACE,YAAA;AANJ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
