// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `pinko-dropdown {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  position: absolute;
  width: 256px;
  background-color: #FFFFFF;
  z-index: 120;
  border-radius: 6px;
  overflow: hidden;
  user-select: none;
  animation: keyframes-overflow-menu-slide-in 150ms;
}
pinko-dropdown div.group {
  display: flex;
  flex-direction: row;
  height: 48px;
  align-items: center;
  padding: 0 16px;
}
pinko-dropdown div.group div.label {
  font-family: "Incinet Semi Bold", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  color: rgba(22, 22, 22, 0.5019607843);
}
pinko-dropdown div.item {
  display: flex;
  flex-direction: row;
  cursor: pointer !important;
  user-select: none;
  height: 40px;
  align-items: center;
  padding: 0 16px;
}
pinko-dropdown div.item:hover {
  background-color: rgba(22, 22, 22, 0.0588235294);
}
pinko-dropdown div.item div.label {
  font-family: "Incinet Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  color: #1F1F1F;
}
pinko-dropdown div.item-selected {
  background-color: #8000F7 !important;
}
pinko-dropdown div.item-selected div.label {
  color: #FFFFFF;
}`, "",{"version":3,"sources":["webpack://./src/pinko/components/pinko-dropdown/pinko-dropdown.scss","webpack://./src/pinko/styles/_mixins.scss","webpack://./src/pinko/styles/_colors.scss","webpack://./src/pinko/styles/_positions.scss","webpack://./src/pinko/styles/_styles.scss"],"names":[],"mappings":"AAKA;ECuFE,2CAAA;EDrFA,kBAAA;EACA,YAAA;EACA,yBEuDY;EFtDZ,YGPa;EHQb,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,iDAAA;AAJF;AAME;ECmBA,aAAA;EACA,mBAAA;EDlBE,YAAA;EACA,mBAAA;EACA,eAAA;AAHJ;AAKI;ECbF,4CAAA;EGsKA,eAAA;EACA,kBAAA;EACA,mBAAA;EJzJI,qCEJgB;AFItB;AAIE;ECOA,aAAA;EACA,mBAAA;EAsBA,0BAAA;EACA,iBAAA;ED5BE,YAAA;EACA,mBAAA;EACA,eAAA;AAAJ;AAEI;EACE,gDE5BQ;AF4Bd;AAGI;ECtCF,0CAAA;EGkBA,eAAA;EACA,kBAAA;EACA,mBAAA;EJoBI,cEvBe;AFyBrB;AACI;EACE,oCAAA;AACN;AACM;EACE,cEzBc;AF0BtB","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
