import "./pinko-select.scss";
import template from "./pinko-select.hbs";
import { Context } from "../../../hiyo/context.js";
import { PinkoSelectOptions } from "./types.js";
import { component, events } from "../../../hiyo/decorators.js";
import { PinkoField } from "../pinko-field/pinko-field.js";
import { PinkoDropdown } from "../pinko-dropdown/pinko-dropdown.js";
import { DropdownItem } from "../pinko-dropdown/types";

@component(template)
@events("click")
export class PinkoSelect extends PinkoField<Context, PinkoSelectOptions> {

    // Properties
    public dropdown: PinkoDropdown;

    // Event handling methods
    //public onKey(key: number): void {};

    public select(): void {
        // Stop propagation
        event.stopPropagation();

        // Hide dropdown if visible
        if (this.dropdown?.isConnected) {
            this.dropdown.remove();
            return;
        }

        // Construct new dropdown
        this.dropdown = new PinkoDropdown(this.context, {
            anchor: "TopLeft",
            start: "BottomLeft",
            offset: [0, 2],
            groups: [
                {
                    name: "Main",
                    items: this.options.items
                }
            ]
        });

        // Assign value
        this.dropdown.onSelect = (item: DropdownItem) => {
            // create value object
            let value = {};
            value[item.name] = item.label;

            // Set value
            this.setValue(value);

            // Submit call
            this.submit();
        }

        // Show dropdown
        this.dropdown.show(this);
    }

    public clear(): void {
        // Stop propagation
        event.stopPropagation();

        // Deselect all items
        for (let item of this.options.items) {
            item.selected = false;
        }

        // Clear value
        this.setValue(null);

        // Submit call
        this.submit();
    }

}