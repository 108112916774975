// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `pinko-input {
  display: block;
  margin-bottom: 24px;
}
pinko-input label {
  font-family: "Incinet Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  color: #1F1F1F;
  margin-bottom: 4px;
}
pinko-input input {
  font-family: "Incinet Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  transition: all 100ms cubic-bezier(0, 0, 0.38, 0.9) 0ms;
  transition-property: border-color;
  display: block;
  width: 100%;
  height: 40px;
  outline: none;
  box-sizing: border-box;
  color: #1F1F1F;
  background-color: #FFFFFF;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid rgba(111, 111, 111, 0.2);
}
pinko-input input:focus {
  border-color: #8000F7;
}
pinko-input input:-webkit-autofill, pinko-input input:-webkit-autofill:hover, pinko-input input:-webkit-autofill:focus, pinko-input input:-webkit-autofill:active {
  -webkit-text-fill-color: #1F1F1F;
  -webkit-box-shadow: 0 0 0 1000px #FFFFFF inset;
}
pinko-input div.description {
  font-family: "Incinet Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  color: rgba(22, 22, 22, 0.5019607843);
}
pinko-input div.reason {
  font-family: "Incinet Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  position: absolute;
  color: #EB0D3F;
  margin-top: 4px;
}
pinko-input[invalid=true] input {
  border-color: #EB0D3F;
}`, "",{"version":3,"sources":["webpack://./src/pinko/components/pinko-input/pinko-input.scss","webpack://./src/pinko/styles/_mixins.scss","webpack://./src/pinko/styles/_styles.scss","webpack://./src/pinko/styles/_colors.scss"],"names":[],"mappings":"AAIA;EACE,cAAA;EACA,mBAAA;AAHF;AAKE;ECPA,0CAAA;ECIA,eAAA;EACA,kBAAA;EACA,mBAAA;EFGE,cGQiB;EHPjB,kBAAA;AAAJ;AAGE;ECbA,0CAAA;ECkBA,eAAA;EACA,kBAAA;EACA,mBAAA;ED8DA,uDAAA;EACA,iCAAA;EDnEE,cAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,cGJiB;EHKjB,yBGyCU;EHxCV,iBAAA;EACA,kBAAA;EACA,0CAAA;AAGJ;AADI;EACE,qBGyBqB;AHtB3B;AAAI;EAIE,gCGlBe;EHmBf,8CAAA;AADN;AAME;ECzCA,0CAAA;ECIA,eAAA;EACA,kBAAA;EACA,mBAAA;EFqCE,qCGxBkB;AHuBtB;AAIE;EC9CA,0CAAA;ECIA,eAAA;EACA,kBAAA;EACA,mBAAA;EF0CE,kBAAA;EACA,cGjDe;EHkDf,eAAA;AACJ;AAGI;EACE,qBGvDa;AHsDnB","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
