import "./pinko-dialog.scss";
import template from "./pinko-dialog.hbs";
import { component, events } from "../../../hiyo/decorators.js";
import { PinkoDialogOptions } from "./types.js";
import { Component } from "../../../hiyo/component.js";
import { Context } from "../../../hiyo/context.js";
import { PinkoOverlay } from "../pinko-overlay/pinko-overlay.js";

@component(template)
export class PinkoDialog<U extends Context, T extends PinkoDialogOptions = PinkoDialogOptions> extends Component<U, T> {

    // Event handling methods
    public onClose(): void {}; // Dialog was closed with no result
    public onSubmit(result?: any): void {}; // Dialog was submitted with result

    public close(): void {
        // Close via overlay if displayed
        if (this.parentComponent instanceof PinkoOverlay) {
            // Close self via overlay
            this.parentComponent.remove();
        }
        // Else remove self directly from DOM
        else {
            this.remove();
        }

        // OnClose handler
        this.onClose();
    }

    public async submit(result?: any): Promise<void> {
        // Close self
        this.close();

        // OnClose handler
        this.onSubmit(result);
    }

}