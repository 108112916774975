import "./main-menu.scss";
import template from "./main-menu.hbs";
import { component, events } from "../../../hiyo/decorators.js";
import { Component } from "../../../hiyo/component.js";
import { IncinetContext } from "../../context/incinet-context.js";
import { MainMenuOptions, MenuGroup } from "./types.js";
import { PinkoDropdown } from "../../../pinko/components/pinko-dropdown/pinko-dropdown.js";
import { DropdownGroup, DropdownItem } from "../../../pinko/components/pinko-dropdown/types.js";
import { StringHelper } from "../../../hiyo/string-helper.js";

@component(template)
export class MainMenu extends Component<IncinetContext, MainMenuOptions> {

    // Properties
    public groups: MenuGroup[];

    // Components
    public dropdown: PinkoDropdown;

    public onCreate() {
        // Create menu items
        this.groups = [
            {
                name: "Insights",
                label: "Insights",
                items: [
                    {
                        name: "Cockpit",
                        label: "Cockpit"
                    }
                ]
            },
            {
                name: "Company",
                label: "Company",
                items: [
                    {
                        name: "Partners",
                        label: "Partners"
                    },
                    {
                        name: "Contracts",
                        label: "Contracts"
                    },
                    {
                        name: "Calculations",
                        label: "Calculations"
                    },
                    {
                        name: "Offers",
                        label: "Offers"
                    },
                    {
                        name: "Agreements",
                        label: "Agreements"
                    },
                    {
                        name: "Tasks",
                        label: "Tasks"
                    }
                ]
            },
            {
                name: "Settings",
                label: "Settings",
                items: [
                    {
                        name: "Users",
                        label: "Users"
                    }
                ]
            },
            {
                name: "User",
                label: "User",
                items: [
                    {
                        name: "Logout",
                        label: "Logout"
                    }
                ]
            }
        ];
    }

    public onAttach() {
        // Hide dropdown menu mouse is outside (excluding self)
        document.querySelector("main").addEventListener("mouseover", () => {
            this.dropdown?.remove();
        })
    }

    public enterGroup(i: number): void {
        // Get selected group
        let group = this.groups[i];

        // Dropdown visible for same group?
        if (this.dropdown?.isConnected && this.dropdown?.options.groups[0].name == group.name) {
            return;
        }

        // Hide menu if active
        if (this.dropdown?.isConnected) {
            this.dropdown.remove();
        }

        // Construct new dropdown
        this.dropdown = new PinkoDropdown(this.context, {
            anchor: (group.name == "User") ? "BottomLeft" : "TopLeft",
            start: (group.name == "User") ? "BottomRight" : "TopRight",
            offset: [4, 0],
            groups: [
                group
            ]
        });

        // Take action on select
        this.dropdown.onSelect = (item: DropdownItem, group?: DropdownGroup) => {
            // Select menu group
            this.selectGroup(i);

            // Call to action
            this.selectItem(item, group);
        }

        // Show menu
        this.dropdown.show(this.querySelector(`div.group-${StringHelper.toKebabCase(group.name)}`));
    }

    public leaveGroup(i: number): void {
        // BO action since we are remove existing dropdown from enter to different group
    }

    public selectGroup(i: number): void {
        // Get selected group
        let group = this.groups[i];

        // Deselect all
        this.groups.forEach(x => x.selected = false);

        // Select item
        group.selected = true;

        // Redraw
        this.render();
    }

    public selectItem(item: DropdownItem, group?: DropdownGroup): void {
        // Deselect all items
        this.groups.forEach(x => {
            x.items.forEach(y => {
                y.selected = false;
            });
        });

        // Select item
        //item.selected = true;

        // Content selector
        let content = this.parentComponent.querySelector("main");

        // Add browser document
        content.innerHTML = `<${StringHelper.toKebabCase(item.name)}-browser></${StringHelper.toKebabCase(item.name)}-browser>`;

        // Logout user
        if (item.name == "Logout") {
            this.fire("logout");
        }
    }
}